.bg-logo {
  @apply bg-[#562672];
}
 
.form_inputbox {
  @apply w-full border border-[#D2D6DB] rounded-lg text-sm;
}
 
.form_button {
  @apply font_hindguntur w-full pt-3.5 pb-3 rounded-full text-base font-medium border border-[#562672] text-white bg-[#562672] hover:opacity-90 tracking-wider focus:ring-4 focus:ring-[#DCBDF5];
}
.form_button_disable {
  @apply font_hindguntur text-base font-medium w-full pt-3.5 pb-3 rounded-full border border-[#e5e7eb] bg-[#f3f4f6] text-[#9da4ae] tracking-wider;
}
.form_inputbox_label {
  @apply font_hindguntur text-sm font-medium block leading-6;
}

.login_copyright {
  @apply font_hindguntur font-medium text-base text-[#4D5761];
}

.primary_purple_700 {
  @apply text-[#8636BA];
}
.primary_purple {
  @apply text-[#562672];
}
.text_gray_500 {
  @apply text-[#6C737F];
}
.text_gray_600 {
  @apply text-[#4D5761];
}
.text_gray_700 {
  @apply text-[#384250];
}
.text_gray_900 {
  @apply text-[#111927];
}
.main_heading {
  @apply text-5xl font-bold leading-10 font_hindguntur_bold;
}
.header_link {
  @apply font_hindguntur text-sm font-medium leading-6 text-[#331643] hover:text-[#9C49D4] hover:font-semibold;
}
.header_nav_link_sm{
  @apply font_inter font-semibold text-base text-[#101828] hover:text-[#9C49D4] hover:font-bold 
}
.header_nav_title {
  @apply font_hindguntur text-base font-medium flex items-start text-[#331643];
}
.header_nav_subtitle {
  @apply font_hindguntur font-normal text-sm leading-6 text-[#503C5C] mt-1;
}
.header_lernmore_link {
  @apply font_hindguntur font-medium text-sm leading-6 text-[#562672];
}
.home_sub_container {
  @apply flex justify-center;
}
.max_container {
  @apply w-full xl:w-[1220px] px-6 xl:px-0;
}
.main_page_container {
  @apply w-full xl:w-[1216px] xl:px-0 px-6;
}
.sm_max_container {
  @apply w-full;
}
.homepage_button {
  @apply font_hindguntur shadow-md px-7 py-4 border rounded-lg max-h-fit flex items-center w-full md:w-fit justify-center;
}
.home_card_header {
  @apply font-bold font_hindguntur_bold text-[2rem] leading-[3rem] text-[#331643];
}
.home_card_para {
  @apply mt-2 font-normal font_hindguntur text-lg text-[#503C5C];
}
.home_card_link {
  @apply flex items-center mt-5 font_hindguntur text-base font-semibold text-[#562672];
}
.footer_subtitle {
  @apply font_hindguntur text-[#DCBDF5] font-semibold text-sm text-left mb-4 mt-4 sm:mt-0 tracking-wider;
}
.footer_link {
  @apply font_hindguntur text-[#FBF6FE] font-semibold text-base text-left block tracking-wider hover:text-violet-200;
}
.list_cource_button {
  @apply flex items-center font_hindguntur text-base font-medium text-[#4D5761] hover:text-[#9E77ED];
}
.list_cource_checkbox_parent {
  @apply items-center space-x-2 flex;
}
.list_cource_checkbox_name {
  @apply text-sm font-medium text-center text-[#424242] rounded-2xl bg-gray-100 px-3 py-1 object-cover;
}
.courses_sidebar_button {
  @apply px-4 py-2.5 border rounded-lg text-base font-semibold;
}
.courselist_sidebar_subtitle {
  @apply font-medium text-base;
}
.courses-heading {
  @apply text-[#FBF6FE] font_hindguntur_bold font-bold text-5xl md:text-7xl leading-[55px] py-2 md:text-center break-words;
}
.courses-paragraph {
  @apply text-[#FBF6FE] font_hindguntur font-normal text-lg mx-auto md:text-center break-words;
}
.courses-button {
  @apply font_hindguntur font-medium text-base w-full md:max-w-fit max-h-fit mt-4 sm:mt-0 flex justify-center items-center py-2.5 px-6 border rounded-full text-nowrap hover:opacity-90 min-h-12;
}
.course_card_category {
  @apply px-2 pt-1 space-y-2 font_hindguntur font-medium text-xs text-center text-[#384250] bg-[#F3F4F6] border rounded-full break-words mr-2 mb-2 border-[#eaecf0] text-nowrap w-fit;
}
.course_card_title {
  @apply font_hindguntur font-semibold text-xl text-[#331643] break-words my-3 overflow-hidden line-clamp-1;
}
.course_card_paragraph {
  @apply font_inter font-normal text-sm text-[#4D5761] break-words overflow-hidden mb-2 line-clamp-3 md:line-clamp-2;
}
.course_key_info{
  @apply font_hindguntur font-normal text-sm text-[#FBF6FE] mt-3 flex items-center  sm:justify-start space-x-2 text-nowrap;
}
.courses_icon_button{
  @apply w-11 h-11 p-3 rounded-full border bg-white text-[#384250] ;
}
.viewcourse_title{
  @apply font_hindguntur mb-6 font-semibold leading-[44px] text-3xl;
}
.viewcourse_subtitle{
  @apply font_hindguntur font-semibold leading-[92px] text-3xl text-[#141414];
}
.viewcourse_paragraph{
  @apply font_hindguntur font-normal text-base text-[#525252] pb-4 mb-6;
}
.viewcourse_fees{
  @apply flex justify-between mb-2 font_hindguntur font-normal text-lg ;
}
.coursepayment_paragraph{
  @apply  text-[#503C5C] font-normal text-lg font_hindguntur;
}
.coursepayment_title{
  @apply font-semibold font_hindguntur_bold mb-2 text-[#331643];
}
.assessment_heading{
  @apply font_hindguntur_bold text-[#331643] font-bold text-center text-5xl leading-[60px] lg:text-7xl lg:leading-[88px] ;
}
.jobs_button_sm{
  @apply text-[#4D5761] md:text-[#384250] font_hindguntur text-base font-medium py-2.5 px-0 sm:px-4 mt-[5px] rounded-full md:border min-w-32 flex items-center justify-center bg-white text-nowrap hover:bg-slate-50 cursor-pointer;
}
.jobs_link{
  @apply  text-[#6C737F] font_hindguntur text-base font-normal flex items-center md:justify-center text-nowrap space-x-2 pr-6 mr-6
}
.jobs_subtitle{
  @apply font_hindguntur font-semibold text-3xl md:text-5xl md:leading-[64px];
}
.jobs_paragraph{
  @apply font_hindguntur font-semibold text-5xl;
}
.jobs_input_placeholder{
  @apply placeholder:text-lg placeholder:font-normal text-[#331643] bg-white
}
.jobs_input_parent{
  @apply shadow-sm mt-1.5 border rounded-lg px-3.5 py-2.5 space-x-2 flex items-center justify-between hover:ring-1 hover:ring-[#562672] hover:z-50
}
.jobs_checkbox{
  @apply h-4 w-4 rounded-xl hover:ring-1 hover:ring-[#562672] cursor-pointer accent-[#562672]
}
.jobs_list_checkbox_parent{
  @apply mt-4 space-x-2 flex mr-4 
}
.jobs_checkbox_label{
  @apply text-[#344054] font_hindguntur font-medium text-sm leading-6
}
.job_inputbox_label{
  @apply font_hindguntur text-sm font-medium block leading-6 text-[#384250] flex-nowrap;
}
.jobs_details_subtitle {
  @apply font_hindguntur_bold font-semibold text-3xl leading-[44px] text-[#141414]
}
.jobs_details_paragraph {
  @apply font_inter font-normal text-base text-[#525252] break-words text-wrap
}
.jobs_details_sticky_para {
  @apply font_inter font-normal text-sm text-[#525252] break-words text-wrap
}
.jobs_details_sticky_para2 {
  @apply font_inter font-bold text-base break-words text-wrap
}
.articles-guides_button{
  @apply text-base font-medium font_hindguntur leading-normal;
}
.article-see-more{
  @apply text-[#4D5761] text-base font-medium font_hindguntur leading-normal hover:text-[#724EB3] hover:font-semibold cursor-pointer;
}
.articles-guides_filter{
  @apply px-0.5 pt-[5px] justify-center items-center flex;
}
.articles-guides_flex-center{
  @apply flex-col justify-center items-center;
}
.articles-guides_flex-start{
  @apply flex-col justify-start items-start;
}
.articles-guides_inlineflex-start{
  @apply justify-start items-center inline-flex;
}
.articles-guides_inlineflex-center{
  @apply justify-center items-center gap-2 inline-flex;
}
.articles-guides_Leavers-chip{
  @apply text-center text-white text-xs font-medium font_hindguntur leading-tight;
}
.articles-guides_course-title{
  @apply h-8 text-[#fbf6fe] text-3xl font-semibold font_hindguntur leading-[44px];
}
.articles-guides_course-desc{
  @apply self-stretch text-[#fbf6fe] text-base font-normal font_hindguntur leading-normal;
}
.articles-guides_mid-container{
  @apply gap-8 flex flex-col lg:flex-row justify-center mb-24 md:px-0 px-6 lg:px-0;
}
.articles-guides-type-heading{
  @apply text-black text-3xl font-semibold font_hindguntur leading-[44px] px-4 lg:px-0 mb-6;
}
.articles-guides_img-style{
  @apply rounded-lg md:max-h-60 md:w-fill-available;
}
.pay_link {
  @apply text-[#4D5761] text-base font-medium font_hindguntur leading-normal hover:text-[#724EB3]
}
.prp_heading{
  @apply font_hindguntur_bold text-[#331643] font-bold text-center text-5xl leading-[60px] lg:text-7xl lg:leading-[88px] ;
}
.prp_paragraph{
  @apply font_hindguntur text-[#331643] font-normal text-center text-lg;
}
.prp_card{
  @apply mb-2 bg-white hover:bg-slate-50 text-[#503C5C] w-full p-4 md:p-6 rounded-2xl border border-[#E5E7EB]
}
.prp_virsion_button{
  @apply text-[#331643] font_hindguntur font-semibold text-2xl leading-9 md:text-3xl md:leading-[44px]
}
.prp_download_button{
  @apply text-[#4D5761] font_hindguntur font-medium text-sm md:text-base pt-[5px] hover:text-black
}
.prp_card_para{
  @apply font_hindguntur text-[#503C5C] font-normal text-sm leading-6;
}
.chat_send_inputbox{
  @apply px-3.5 py-2.5 flex items-center font_hindguntur rounded-lg bg-white font-normal text-base w-full lg:w-[70%] border focus_ring
}
.chat_send_button{
  @apply px-2 sm:px-4 py-2.5 flex items-center justify-center space-x-1.5 font_hindguntur rounded-full bg-[#7953C2] text-white font-medium text-base min-h-11 min-w-11 border border-[#562672]
}
.chat_send_disable_button{
  @apply px-2 sm:px-4 py-2.5 flex items-center justify-center space-x-1.5 font_hindguntur rounded-full font-medium text-base min-h-11 min-w-11 border-[#e5e7eb] bg-[#f3f4f6] text-[#9da4ae] hover:text-[#9da4ae] tracking-wider hover:bg-[#f3f4f6]
}
.prp_card_heading{
  @apply text-[#331643] font_hindguntur_bold font-semibold md:font-bold text-2xl md:text-[32px] leading-9 md:leading-[48px] mt-1
}
.prp_card_category {
  @apply px-2 py-1 pt-1.5 flex justify-center items-center font_hindguntur font-medium text-xs text-center text-[#067647] bg-[#ECFDF3] border border-[#ABEFC6] rounded-full break-words text-nowrap space-x-1;
}
.prp_date_parent{
  @apply md:flex items-center space-y-1 md:space-y-0 md:space-x-4
}
.header_profile_box_parent{
  @apply mx-1.5 flex items-center space-x-2 p-2
}
.header_profile_box_link{
  @apply font_inter text-[#503C5C] font-medium text-sm hover:font-semibold hover:text-[#9C49D4]
}
.courses-button-search {
  @apply font_hindguntur font-medium text-base w-full max-h-fit mt-4 sm:mt-0 flex justify-start items-start py-2.5 px-6 border rounded-full text-nowrap min-h-12 hover:opacity-90;
}
.dos_card_title{
  @apply text-[#141414] font_hindguntur font-semibold text-4xl leading-[52px] break-words line-clamp-1
}
.dos_card_paragraph{
  @apply text-[#141414] font_hindguntur font-normal text-base break-words line-clamp-3
}
.dos_card_chip{
  @apply px-2.5 py-1 pt-1.5 flex items-center justify-center text-[#562672] bg-[#FBF6FE] border border-[#C792EE] font_hindguntur font-medium text-sm rounded-full text-nowrap mt-2 me-2 min-h-7
}
.dos_search_paragraph{
  @apply text-[#503C5C] font_hindguntur font-normal text-base 
}
.dos_search_chip{
  @apply px-4 py-[19px] flex items-center font_hindguntur font-medium text-lg text-nowrap rounded-lg sm:min-w-96 w-full 
}
.dos_search_chip_active{
  @apply px-4 py-[19px] flex items-center font_hindguntur font-medium text-lg text-nowrap rounded-lg sm:min-w-96 w-full
}
.dos_search_input_label{
  @apply font_hindguntur font-medium text-sm text-[#384250]
}
.dos_search_input_parent{
  @apply px-3.5 border border-[#D2D6DB] font_hindguntur md:w-[320px] rounded-lg font_hindguntur font-normal text-sm flex items-center space-x-2 hover:border-[#562672] w-full mr-0 md:mr-4 mb-4 md:mb-0 min-h-[2.8rem]
}
.dos_search_input{
  @apply w-full py-2.5 mr-2 placeholder:text-black rounded-lg focus:ring-0 focus:outline-0 flex items-center font_hindguntur text-base font-normal text-[#331643]
}
.dos_search_button{
  @apply space-x-1 px-3 py-2 border border-[#DCBDF5] flex items-center justify-center font_hindguntur font-medium text-sm rounded-full hover:bg-[#F4EBFC] w-full md:w-fit min-h-[2.8rem]
}
.dos_result_page_heading{
  @apply font_hindguntur_bold text-[#331643] font-bold text-center text-5xl leading-[60px] lg:text-7xl lg:leading-[88px] ;
}
.dos_result_page_paragraph{
  @apply font_hindguntur text-[#331643] font-normal text-center text-lg;
}
.dos_result_page_sidebar_title{
  @apply font_hindguntur text-[#503C5C] font-medium text-sm;
}
.dos_services_details_heading{
  @apply font_hindguntur_bold text-[#331643] font-bold text-5xl break-words text-wrap;
}
.dos_services_details_paragraph{
  @apply font_hindguntur text-[#503C5C] font-normal text-base break-words text-wrap;
}
.dos_services_details_chips{
  @apply px-2.5 py-1 font_hindguntur text-[#562672] bg-[#FBF6FE] border border-[#C792EE] font-medium text-sm flex items-center justify-center rounded-full text-nowrap;
}
.dos_details_calender_title{
  @apply font_hindguntur font-medium text-lg text-[#331643]
}
.dos_details_calender_ele{
  @apply font_hindguntur font-normal text-base 
}
.dos_details_contact_ele{
  @apply font_hindguntur font-normal text-sm 
}
.dos_input{
  @apply font_hindguntur rounded-lg font_hindguntur font-normal text-black text-lg w-full
}
.dos_viewservice_btn{
 @apply pl-4 mr-2 font_inter text-sm font-semibold text-[#331643] hover:text-[#8636BA] flex items-start space-x-2 max-h-4
}
.dos_view_services{
  @apply pl-4 mr-2 font_inter text-sm font-semibold text-nowrap mt-0.5
}
.card_parent{
  @apply transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-lg rounded-xl
}
.card_img{
  @apply object-cover w-full h-full
}
.card_img_parent{
  @apply h-64 max-h-60 rounded-2xl flex items-center justify-center bg-slate-800 overflow-hidden
}
.dos_card_subcategory {
  @apply px-2.5 pt-1 space-y-2 font_hindguntur font-medium text-sm leading-6 text-center text-[#344054] bg-[#F3F4F6] border rounded-full break-words mr-2 mb-2 border-[#EAECF0] text-wrap cursor-pointer; 
}
.dos_card_subcategory_active {
  @apply px-2.5 pt-1 space-y-2 font_hindguntur font-medium text-sm leading-6 text-center text-[#562672] bg-[#FBF6FE] border border-[#C792EE] rounded-full break-words mr-2 mb-2 text-wrap cursor-pointer;
}  
.heading_input_parent{
  @apply w-full md:w-1/2 border flex items-center justify-between px-4 md:px-6 hover:ring-1 hover:ring-[#562672] hover:z-50 min-h-[4rem] lg:min-h-[4.75rem]
}
.favourate_heart_parent{
  @apply bg-white flex items-center justify-center rounded-full absolute top-[11rem] left-[1rem] 
}
.favourate_heart{
  @apply p-2 hover:scale-105
}
.wallet_heading{
  @apply font_hindguntur_bold font-bold text-5xl text-center lg:text-left text-[#331643] leading-[4rem] 
}
.wallet_paragraph{
  @apply font_hindguntur text-center lg:text-left text-[#503C5C] max-w-md md:max-w-full
}
.wallet_card{
  @apply p-5 md:p-6  bg-[#F9FAFB] hover:bg-gray-100
}
.wallet_card_title{
  @apply text-xl text-[#111927] font-semibold font_hindguntur
}
.wallet_card_paragraph{
  @apply text-gray-600 font_hindguntur 
}
.wallet_card_link{
  @apply flex items-center cursor-pointer mt-4 md:mt-5 text-[#724EB3] hover:text-[#9C49D4] space-x-2
}
.wallet_button {
  @apply font_hindguntur font-medium text-base w-full lg:max-w-fit max-h-fit mt-4 sm:mt-0 flex justify-center items-center py-2.5 px-6 border rounded-full text-nowrap hover:bg-slate-50 min-h-12  bg-white text-[#384250];
}
.thead-title {
  @apply text-[#331643] py-3 px-6 bg-[#F9FAFB] text-sm font-medium font_hindguntur leading-normal border-b border-[#E5E7EB]
}
.tbody-common {
  @apply py-4 px-6 border-b border-[#E5E7EB]
}
.tbody-content {
  @apply text-[#503C5C] text-sm font-normal font_hindguntur leading-normal
}
.tbody-filename {
  @apply text-[#331643] text-base font-medium font_hindguntur leading-normal
}
.max_cv_container{
  @apply w-full xl:w-[1220px] xl:max-w-[1220px] px-6 xl:px-0
}
.logo_parent{
  @apply max-w-44 min-h-[4.5remn] flex items-center justify-center w-full
}
.profile_title{
  @apply text-[#384250] text-sm font_hindguntur font-medium mb-1.5
}
.profile_box{
  @apply p-2.5 pb-1.5  shadow-sm rounded-lg border font_hindguntur border-[#D2D6DB] bg-gray-50 text-[#6C737F]
}
.focus_ring{
  @apply focus:outline-none focus:ring-0
}
.mypages_heading{
  @apply text-[#331643] font_hindguntur_bold text-5xl sm:text-7xl font-bold
}
.mypages_paragraph{
  @apply text-[#331643] font_hindguntur text-lg
}
.mypages_heading_container{
  @apply py-16 md:py-24 px-6
}
.box_container_title{
  @apply font_hindguntur text-3xl text-[#331643] font-semibold
}
.booking_grid_container{
  @apply  mx-auto grid grid-cols-1 md:grid-cols-2 md:pl-4 lg:grid-cols-3 gap-8 mt-6
}
.card_containt_spacing{
  @apply pt-4 space-y-2 p-2
}
.card_alignment{
  @apply w-full lg:max-w-96 cursor-pointer relative inline-block min-h-[22.75rem]
}
.landing_page_input{
  @apply w-full lg:w-1/2 border font_inter flex items-center justify-between py-3 md:py-6 px-6 space-x-4 hover:ring-1 hover:ring-[#562672] hover:z-50 max-h-[4.75rem] bg-white
}
.landing_page_input_parent{
 @apply p-4 lg:flex items-center mt-3 text-lg lg:mt-0 bg-white rounded-2xl w-full max-w-5xl
}
.landing_page_input_button{
  @apply md:px-[38px] py-[13px] bg-[#562672] space-x-2.5 text-white w-full lg:w-fit hover:opacity-90 flex items-center justify-center rounded-full lg:h-[76px] font_hindguntur font-medium text-lg text-nowrap mt-4 lg:mt-0 lg:ml-4
}
.page_subtitle{
  @apply font_hindguntur font-medium text-lg text-[#331643]
}
.purple_button{
  @apply flex items-center justify-center space-x-1.5 bg-[#562672] text-white  hover:text-[#562672] hover:bg-white py-3 px-[1.12rem] border rounded-full
}
.transparent_button{
  @apply flex items-center justify-center space-x-1.5 bg-none text-white  hover:text-gray-700 hover:bg-white py-3 px-[1.12rem] border rounded-full
}
.landing_page_background{
  @apply bg-[#562672] xl:max-w-[86.2rem] h-full xl:max-h-[37.75rem] xl:h-[37.75rem] text-violet-100 w-full py-16 lg:px-20 lg:py-24 rounded-2xl 2xl:mx-0 relative mx-2
}
.landing_page_alignment{
  @apply mx-6 xl:mx-2 
}
.landing_page_header_alignment{
  @apply mx-2 2xl:mx-0
}
.content_header_parent{
  @apply bg-[#562672] bg-img-home rounded-2xl text-violet-100 w-full px-6 py-16 lg:px-24 lg:py-20 min-h-fit max-w-[86rem]
}
.content_heading{
  @apply text-[#FBF6FE] text-[48px] leading-[64px] lg:text-[64px] lg:leading-[80px] font-bold font_hindguntur_bold max-w-3xl w-full
}
.content_paragraph{
  @apply font-normal font_hindguntur text-[18px] leading-[28px] text-[#FBF6FE] max-w-xl break-words
}
.content_login_parent{
  @apply mt-6 md:mt-14 sm:flex sm:justify-between items-center lg:max-w-6xl w-full
}
.content_login_button {
  @apply font_hindguntur px-4 py-2.5 border max-h-fit flex items-center justify-center w-full sm:w-fit font-medium text-[#331643] bg-white border-white hover:opacity-90 rounded-full;
}
.content_support_text {
  @apply font_hindguntur font-normal text-lg block mt-6 lg:mt-0
}
.content_card{
  @apply p-6 bg-[#F3F4F6] rounded-3xl hover:bg-violet-100 flex flex-col
}
.content_card_chip{
  @apply flex items-center justify-center border rounded-full bg-[#F9FAFB] text-nowrap max-w-max pt-[3px] px-2.5 text-[#344054] font_hindguntur text-[14px] font-medium leading-[24px] h-7 border-[#EAECF0]
}
.mypage_heading_alignment{
  @apply py-16 lg:py-24
}
.mypage_heading_subtitle {
  @apply text-3xl leading-[2.75rem] font-semibold break-words font_hindguntur_bold mb-4 text-[#331643]
}
.content_second_card_title{
  @apply text-xl leading-[2rem] text-[#331643] font-semibold font_hindguntur mt-16
}
.content_second_card_paragraph{
  @apply text-base text-[#4D5761] font-normal font_hindguntur mt-2
}
.content_second_card_button{
  @apply font_inter text-base font-semibold flex items-center cursor-pointer mt-5 text-[#724EB3]
}
.content_section_title{
  @apply text-[#331643] font_hindguntur_bold text-[32px] sm:text-5xl font-bold leading-[48px] sm:leading-[64px]
}
.content_section_paragraph{
  @apply text-[#503C5C] text-base font_hindguntur
}
.service_leaver_year_chips{
  @apply hover:bg-[#F9FAFB] hover:text-[#384250] hover:rounded-md text-[#70707B] text-[16px] font_hindguntur leading-6 p-2.5 flex justify-center items-center
}
.service_leaver_CRP_content_title{
  @apply text-xl text-[#331643] font-semibold font_hindguntur
}
.service_leaver_CRP_content{
  @apply text-[#503C5C] text-[16px] font-normal leading-[24px] font_hindguntur
}
.employer_paragraph{
  @apply font_hindguntur text-[18px] leading-7 font-normal text-[#503C5C]
}
.employers_containt_section{
  @apply w-full lg:min-w-[593px] sm:mt-6
}
.employer_content_img{
  @apply max-h-[28.37rem] w-full mx-auto
}
.landing_view_page{
  @apply w-full max-w-[1376px] rounded-2xl bg-[#562672] flex justify-center items-center py-12 md:py-[95px] md:px-[80px] 
}
.main_input_page_button{
  @apply md:px-[38px] py-[13px] bg-[#562672] space-x-2.5 text-[#FBF6FE] w-full lg:w-fit hover:opacity-90 flex items-center justify-center rounded-full lg:h-[76px]
}
.card{
  @apply w-full md:max-w-96 inline-block min-h-[364px] cursor-pointer rounded-lg relative 
}
.booking_heading{
  @apply text-[#331643] font_hindguntur_bold text-5xl sm:text-7xl font-bold
}
.booking_paragraph{
  @apply text-[#331643] font_hindguntur text-lg
}
.sp_design_title{
  @apply sm:text-center text-[#331643] text-xl font-semibold font_hindguntur leading-loose max-w-[15rem] sm:mx-auto sm:mt-[1.13rem]
}
.sp_design_paragraph{
  @apply sm:text-center text-[#4f3b5b] text-base font-normal font_hindguntur leading-normal max-w-[15rem] sm:mx-auto
}
.dialog_button{
  @apply w-full rounded-full border flex items-center justify-center py-3 font_hindguntur shadow-sm font-medium text-base 
}
.sector_title {
  @apply text-white text-2xl lg:text-3xl font-semibold font_hindguntur leading-[44px]
}
.alertContainer {
  @apply bg-[#B1A3E2] rounded-lg text-[#331643] flex justify-between items-center h-14 px-3.5 py-3 font_hindguntur font-medium text-sm leading-6 relative p-4
}
.alretMe {
   @apply border-[#331643] border text-[#331643] bg-transparent w-fit px-[13px] py-[7px] relative font_hindguntur font-medium text-xs leading-[18px]
}
.alretOn {
    @apply border-[#331643] border text-[#331643] bg-transparent w-fit px-3 py-1 relative font_hindguntur font-medium text-xs leading-[18px]
}
.payment_sm_img{
  @apply w-24 h-24 mr-4 rounded-lg object-cover
}  

.header_sm_nav_btn{
  @apply flex items-center justify-center py-2.5 px-3.5 space-x-1.5 bg-white w-full rounded-full border border-[#D2D6DB] shadow-sm
}
.header_sm_nav_btn_text{
  @apply font_hindguntur font-medium text-sm mt-[5px] text-[#384250]
}
.active-link {
  @apply text-[#9c49d4] font-bold font_hindguntur
}
.learndiscoverParent{
  @apply space-y-4 w-full lg:max-w-[592px] mt-8 md:mt-0 cursor-pointer
}