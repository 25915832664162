// main {
//   display: block;
//   background: $white;
//   margin: 0;
//   padding: $gutter / 2 0;

//   min-height: 50vh;

//   @include media(tablet) {
//     padding: 15px 0;
//     min-height: 100vh;
//   }
//   @include media(mobile) {
//     padding: 15px 0;
//     min-height: 100vh;
//   }
// }
@import '../helpers/variables/base';
.breadcrumbs {
  
  display: none;
  color: $black;

  @include media(tablet) {
    display: block;
    margin: 0;
    margin-top: 0;
    font-size: 14px;

    .breadcrumbs-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-link {
      text-decoration: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .breadcrumbs-list-item {
      display: inline-block;
      position: relative;

      &:before {
        content: "/";
        color: $black;
        display: block;
        position: absolute;
        top: 3px;
        left: -4px;
        margin: auto 0;
      }

      &:first-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }
}
.modal{
  display: block;
  height: 100vh;
  background: #00000052;
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  overflow: hidden;
}
.custmodal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  min-width: 400px;
  button{
    margin-left: 0px;
  }
  p {
    font-size: 19px;
  }
}
.modal-dialog{
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 4px solid #000;
  min-width: 400px;
  width: 600px;
  .modal-header{
    padding: 20px;
    border-bottom: 1px solid #ddd;
    overflow: auto;
    position: relative;
    .modal-title{
      font-weight: 600;
      margin-bottom: 0;
    }
    .close{
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
  .modal-body{
    padding: 20px;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .modal-footer{
    padding: 20px;
    border-top: 1px solid #ddd;
    text-align: right;
  }
  button{
    margin-left: 0px;
  }
  .heading-large{
    margin-top: 0;
  }
  p {
    font-size: 19px;
  }
}
.sign-up-page{
  .error-summary{
      margin-top: 0;
  }
}
#id-section-help-initialPassword {
  width: 50%;
}
#content-activity-popup{
  .form-group{
    .form-control-1-16{
      label{
        font-size: 19px;
    }
    }
    .form-control-1-10{
      label{
        font-size: 19px;
      }
    }
    .form-label-b19{
      font-size: 24px;
      font-family: 'HindGuntur-Bold', sans-serif;
    }
  }
  #id-endDate{
    .form-group{
      margin-bottom: 5px;
    }
  }
}
#generate_cv {
  // .inlineRadioButtons {
  //   display: inherit;
  // }
}
#myCvUpload {
  .inlineRadioButtons {
    display: block;
    margin-bottom: 30px;
  }
}

#planPathwayDescription{
  .selectOptions{
    button.column-fifth{
      height: 5em!important;
    }
  }
} 
/*for track based on age*/
.track-young{
  display: none;
}
.showTrack{
  display: none;
}
.track-adult{
  display: inline-block;
}
.lowerAge {
  .track-young{
    display: inline-block;
  }
  .track-adult{
    display: none!important;
  }
  .goals-alert-container{
    background: none!important;
    border: 12px solid #F7E113!important;
    border-radius: 12px;
    overflow: auto;
    margin-bottom: 40px!important;
    .showTrack{
      display: block!important;
      text-align: center;
      padding-top: 50px;
    }
    .width20{
      width: 20%;
      padding-left: 3%;
      padding-right: 3%;
      float: left;
      img{
        width: 60%;
      }
    }
    .goals-container{
      float: left;
      width: 48%;
      .goals-alert-heading{
        text-align: center;
        font-size: 36px;
        margin-top: 20px;
      }
    }
  }
  #tabWrapper.tabWrapper { 
    ul {
      height: auto!important;
      border-color: transparent!important;
      text-align: center;
      border-bottom: none!important;
      li{
        float: none!important;
        background-color: #fff!important;
        height: auto!important;
        padding: 14px 19px 8px 19px!important;
        a{
          text-decoration: none!important;
          img{
            width: 60px;
            display: block!important;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
          }
        }
      }
      li.active{
        background-color: #F3F2F1!important;
        border-color: transparent!important;
        border-bottom: 4px solid #3770B3!important;
        border-radius: 12px 12px 0 0;
        margin-top: 0!important;
        padding: 14px 19px 8px 19px!important;
      }
    }
  }
  .plan-new-wrapper{
    background-color: #85D5D9;
    border-color: transparent!important;
    border-radius: 12px;
    margin-top: -6px;
    .gantt-container{
      max-width: 917px!important;
    }
    .upper-container{
      .months-container{
        .previous-month{
          border-left-color: transparent!important;
        }
        //color: #fff!important;
      }
    }
    .pathway-container{
      .pathway-timeline-education-training-and-employment{
        background: rgba(244, 119, 56, 1);
      }
      .pathway-timeline-finance{
        background: rgba(40, 161, 151, 1);
      }
      .pathway-timeline-health-and-wellbeing{
        background: rgba(86, 148, 202, 1);
      }
      .pathway-timeline-housing{
        background: rgba(243, 153, 190, 1);
      }
      .pathway-timeline-drugs-and-alcohol{
        background: rgba(133, 153, 75, 1);
      }
      .pathway-timeline-family{
        background: rgba(111, 114, 175, 1);
      }
      .pathway-timeline-behaviour-and-attitudes{
        background: rgba(145, 43, 136, 1);
      }
      .timeline-decorator-container{
        background: rgba(255, 255, 255, 0.6);
      }
      .pathway-label-container{
        display: block!important;
        position: relative;
        border-radius: 10px 0 0 10px;
        .centre_aligned{
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          display: block;
          margin: auto;
          margin-bottom: 3px;
        }
      }
      .pathway-timeline{
        width: calc(100% - 134px)!important;
      }
      .triangle-right {
        width: 0;
        height: auto;
        border-top: 43px solid transparent;
        border-left: 50px solid #555;
        border-bottom: 43px solid transparent;
        position: relative;
      }
    }
    // .entry-label-content{
    //   color: #fff!important;
    // }
    .key-information-panel{
      border: 4px solid #FFDD00;
      border-radius: 8px;
      position: relative;
      padding: 20px 10px!important;
      margin-bottom: 80px!important;
      .quarter-section{
        position: absolute;
        width: 100%!important;
        left: 0;
        text-align: center;
        bottom: -65px;
        img{
          margin: auto;
        }
      }
    }
  }
  .entry-panel{
    border-radius: 15px;
    padding: 30px 40px 30px!important;
    position: relative;
  }
  app-entry-comment{
    .entry-panel{
      background: #AF4AF7!important;
      color: #fff!important;
      padding: 25px 40px 25px!important;
      .triangle-left {
        width: 0;
        height: auto;
        border-top: 20px solid transparent;
        border-right: 30px solid #AF4AF7;
        border-bottom: 20px solid transparent;
        position: absolute;
        left: -25px;
        bottom: 25px;
      }
    }
  }
  app-entry-appointment, app-entry-system, app-entry-intervention, app-entry-induction{
    .entry-panel{
      background: #ffffff!important;
    }
  }
  app-entry-intervention{
    .customPad{
      padding: 30px 40px 80px!important;
    }
  }
  .f-structure-entry-container{
    .f-line{
      background-color: transparent!important;
    }
    .f-line-container{
      margin-right: 0!important;
      .f-line-icon-container{
        background-color: #EC2589!important;
        border:transparent!important;
        border-radius: 10px!important;
        width: 65px!important;
        height: 57px!important;
        margin-top: 10px;
        z-index: 9;
      }
    }
    .entry-panel-container{
      margin-top: -20px;
      margin-left: -25px;
      .entry-title{
        background: #EC2589;
        color: #fff;
        display: inline-block;
        padding: 14px 15px 7px 15px;
        margin-left: -21px;
        border-radius: 10px;
        margin-bottom: 5px;
      }
    }
    .today-line-panel-container{
      .today-line-divider{
       background-color: #EC2589!important;
     }
   }
  }
  .left-container{
    position: relative!important;
    .image-div{
        position: absolute;
        right: 0;
        top: 18px;
    }
  }
  .right-container{
    position: absolute;
    left: 0;
    bottom: -67px;
    width: 100%!important;
    text-align: center;
    display: block!important;
    span{
      margin: auto;
      vertical-align: top;
    }
    .track-ml20{
      margin-left: 20px;
    }
    .status-spacer{
      background-color: transparent;
      border: none;
    }
  }
  .image-system-div{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-33%, -50%);
  } 
  .track-tab-container{
    .entry-pathway-label{
      border-radius: 10px 0 0 10px;
      height: 30px;
    }
    .small-triangle-right{
      width: 0;
      height: auto;
      border-top: 19px solid transparent;
      border-left: 30px solid #555;
      border-bottom: 19px solid transparent;
    }
  }
  .icon_align{
    margin-right: 7px;
    margin-left: 7px;
  }
  .load-more-container{
    padding-top: 60px!important;
    a{
      margin-left: 100px!important;
    }
  }
}
@media screen and (max-width: 480px){
  .lowerAge {
    .goals-alert-container {
      .width20{
        display: none!important;
      }
      .goals-container{
        width: 100%!important;
      }
    }
    #tabWrapper.tabWrapper{
      ul {
        overflow: hidden!important;
        li {
          width: calc(100% - 30px)!important;
          a{
            img{
              display: inline-block!important;
              width: 26px!important;
            }
          }
        }
      }
    }
    .plan-new-wrapper{
      margin-top: 10px!important;
      .pathway-container{
        .pathway-label-container{
          width: 26px!important;
        }
        .pathway-timeline{
          width: calc(100% - 32px) !important;
        }
        .triangle-right{
          display: none!important;
        }
      }
    } 
    .image-div, .image-system-div{
      display: none!important;
    }
    .f-structure-entry-container{
      .entry-panel-container{
        margin-left: 0!important;
      }
    }
    .entry-pathway-label{
      height: 35px!important;
      p{
        font-size: 12px!important;
      }
    }
  }
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hiddenForAT {
  display: none !important;
}
/*changes for TPS app*/
.header {
  .header-container{
    // background-color: #0b0c0c;
    border-bottom: 10px solid;
    border-color: var(--secondary-color);	
    max-width: 100%;
    height: 55px;
    // padding: 7px 0px;
    a.header-link-home{
      height: 46px;
      .header-logotype {
        img{
          height: 53px;
          // filter: brightness(0) invert(1);
        }
      }
      &:focus{
        box-shadow: none;
        .header-logotype {
          img{
            // filter: brightness(0);
          }
        }
      }
    }
    .rightNav{
      width: auto;
      li{
        margin-top: 5px;
      }
      :nth-child(3){
        display: none;
      }
      a{
        // color: #fff!important;
        font-family: 'HindGuntur-Regular', sans-serif;
        text-decoration: none;
        margin-right: 0;
        &:hover{
          text-decoration: underline;
        }
        &:focus{
          color: #0b0c0c!important;
        }
      }
    }
  }
  .menu{
    border-bottom: 1px solid #b1b4b6;
    background-color: #f8f8f8;
    .header-navigation-container{
      background: none!important;
      border: none;
      ul{
        margin-bottom: 0;
        a{
          font-size: 19px!important;
        }
      }
    }
  }
}
//New-GDS-Footer
.govuk-footer {
  padding-bottom: 25px;
  padding-top: 40px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  border-top: 1px solid #b1b4b6;
  color: #0b0c0c;
  background: #f3f2f1;
}
.govuk-width-container {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1024px) {
    margin: 0 10px;
  }
}
.govuk-footer__link:link, .govuk-footer__link:visited{
  color: #0b0c0c ;
  margin-right: 0px;
  &focus{
    outline: 3px solid rgba(0,0,0,0);
    color: #0b0c0c;
    background-color: #fd0;
    -webkit-box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    box-shadow: 0 -2px #fd0, 0 4px #0b0c0c;
    text-decoration: none;
  }
  &:hover{
    text-decoration-thickness: 1.5px !important;
    color: rgba(11,12,12,.99) !important;
  }
}
.govuk-footer__meta {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
}
.govuk-footer__copyright-logo {
  display: inline-block;
  min-width: 125px;
  padding-top: 112px;
  background-image: url(/assets/images/govuk-crest.png);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 125px 102px;
  text-align: center;
  white-space: nowrap;
}
.govuk-footer__meta-item--grow {
  flex: 1;
}
.govuk-footer__licence-logo {
  margin-right: 10px;
}
.govuk-footer__meta-item {
  margin-bottom: 25px;
}
@media (max-width: 844px) and (orientation: landscape) {
  .h5p-interactive-video {
      .h5p-video {
        width: 80% !important;
        margin: auto !important;
      }
  }
}

.courses-search-section{
  overflow: hidden;
  padding: 40px 0 60px;
  background: #e0eef8;
  margin-bottom: 40px;
  .nomargin {
    margin: 0 ;
  }
  .heading-large {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .search-field{
    margin-top: 20px;
  }
  .ml40 {
    margin-left: 40px;
  }
  .form-control {
    // height: 27px;
    padding: 5px;
  }
  button#id-submit-button {
    margin-left: 10px;
  }
}
.heart-favourite-container {
  // float: right !important;
  margin-top: 5px;
}
//Serach result new GDS style
.search-results {
  display: flex;
  flex-direction: column;
  a {
    &:hover {
      background: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
}
vg-controls{
  justify-content: space-between;
  div{
    display: -webkit-box;
  }
}
/*history timeline*/
.moj-timeline{
  // overflow: hidden;
  position: relative;
  .moj-timeline__item{
    padding-bottom: 20px;
    padding-left: 20px;
    position: relative;
      &::before{
        background-color: #1d70b8;
        content: "";
        height: 5px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 15px;
      }
    .moj-timeline__header{
      .moj-timeline__title{
        font-size: 19px;
        line-height: 1.3157894737;
        font-family: 'HindGuntur-Bold', sans-serif;
      }
    }
    .moj-timeline__date{
      font-size: 16px;
      line-height: 1.25;
      font-family: 'HindGuntur-Regular', sans-serif;
      font-weight: 400;
    }
    .moj-timeline__description{
      ul{
        li{
          display: list-item;
          margin-left: 25px;
          position: relative;
          a{
            position: absolute;
            top: 12px;
          }
          &::marker{
            font-size: 30px;
            color: #333;
          }
        }        
      }
    }
  }
  &::before{
    background-color: #1d70b8;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 10px;
    width: 5px;
    @media (max-width: 900px) {
      height: 124%;
    }
  }
}
.entry-title {
  font-family: "HindGuntur-Bold", sans-serif;
  font-size: 19px !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.entry-subtitle {
  font-size: 16px !important;
  color: #505a5f;
}
.entry-system-content {
  font-family: "HindGuntur-Regular", sans-serif !important;
  word-break: break-all;
  margin-bottom: 0px;
}
.entry-pathway-container .entry-subtitle {
  font-size: 19px !important;
}
.health-search{
  width: 73%!important;
}
.load-more-container{
  a.load-more-btn:hover {
    color: #003078!important;
    text-decoration-thickness: 3px!important;
    box-shadow: none;
  }
}

.data-container {
  background-color: #f1f1f1;
  padding: 10px 10px 10px 10px;
  margin-left: -5px;
}
//style for Action plan surveyjs
.startAssessment .sv_main .sv_container{
  color: #0b0c0c !important;
   font-size: 19px;
}
.startAssessment .sv_main.sv_frame .sv_container .sv_header {
  color: #6d7072;
  padding: 1em 1em 1em 0;
}
.startAssessment .sv_main .sv_container .sv_header {
  font-size: 2em;
  font-weight: 300;
  text-align: left;
  margin: 0;
}

.startAssessment .sv_header {
  background: #fff;
}

.startAssessment .sv_header span {
  color: #626a6e;
  font-weight: 700;
  font-size: 36px;
}
.startAssessment .sv_main.sv_default_css{
.sv_header{
 color: #0b0c0c!important;
}
input[type="radio"]:focus {
  ~ .sv_q_rating_item_text {
    outline: 3px solid #fd0!important;
  }
}
}

.startAssessment .sv_qstn {
 display: inline-block;
 vertical-align: top;
}
.startAssessment .sv_row .sv_qstn:last-child{
 float: none;
}
.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q {
 padding: 3px 15px 3px 4px;
 box-sizing: border-box;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
 background: #fff;
 margin-bottom: 20px;
 position: relative;
 overflow: hidden;
}
.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn.myCustomClass{
    padding-left: 20px;
}
.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_title{
 color: #0b0c0c;
 -webkit-font-smoothing: antialiased;
 line-height: 1.09375;
 display: block;
 font-weight: bold;
 margin: 0.5em 0;
 font-size: 24px;
 font-family: "HindGuntur-Bold", sans-serif;

}

.startAssessment div.sv_q_rating{
 margin-top: 25px;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating{
line-height: 2em;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating label{
line-height: 2em;
 font-family: "HindGuntur-Regular", sans-serif !important;
 font-weight: normal !important;
}
.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item{
 cursor: pointer;
 word-spacing: -0.3em;
 display: inline;
 margin-right: 20px;
}


.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=radio], .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=checkbox]{
margin: 0;
 margin-right: 0.55em;
 width: 1.2em;
 height: 1.2em;
 vertical-align: middle;
 font-size: 1em;
 margin-top: -0.1em;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > *{
 margin-bottom: 15px !important;
 line-height: 1.31579;
 word-spacing: initial;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text{
display: inline-block;
 padding: 1px 10px 3px 10px !important;
 margin-left: -1px;
 text-align: center;
 box-shadow: 0 0 0 3px #fff !important;
 font-weight: normal;
 font-size: 19px;
}
.startAssessment .sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text{
  background: #0b0c0c!important;
  
}

.startAssessment .sv_main.sv_default_css .sv_q_rating_item.active{
  input[type="radio"]{
    + .sv_q_rating_item_text {
      //color: #fd0;
    }
    &:focus{
      ~ .sv_q_rating_item_text {
        outline: 3px solid #fd0!important;
      }
    }
  }
}
.startAssessment span.sv_q_rating_item_text{
   min-width: 0px !important;
   margin-right: 0px;
   border: 2px solid black !important;
   height: auto;
   width: auto;
}
.startAssessment .sv_main.sv_default_css .sv_p_root > .sv_row{
 background: #fff!important;
}
.startAssessment .sv_nav{
  display: none;
}

.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn.myCustomClass .sv_q_erbox div > span:first-child{
    display: block;
    width: 5px;
    min-height: 10px;
    height: 51vh;
    background: #d4351c;
    position: absolute;
    top: 0;
    left: 0px;
}
.startAssessment .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]){
    color: #d4351c;
    font-weight: bold;
}
.startAssessment .status-tags {
  margin-top: 15px;
  span {
    margin-bottom: 10px;
  }
  div {
    display: inline-block;
    margin-right: 2%;
  }
}
.startAssessment .descrip{
  margin-bottom: 5px;
}
.startAssessment .status-tag {
  display: inline-block !important;
  outline: 2px solid transparent;
  outline-offset: -2px;
  color: #fff;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase !important;
  line-height: 1;
  padding: 6px 8px 4px;
  margin: 2px;
  font-family: 'HindGuntur-Bold', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  width: auto;
  text-align: center;
  &.red {
    color: #fff;
    background: #d6473b;
  }
  &.orange {
    color: #fff;
    background: #f07925;
  }
  &.green {
    color: #fff;
    background: #a9c73e;
  }
  &.lightBlue {
    color: #fff;
    background: #41aba8;
  }
  &.yellow {
    color: #fff;
    background: #f7ad2c;
  }
}
.tabscroll{
  .tabWrapper{
    margin-right: 0!important;
    ul{
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-top: 5px;
      height: 65px;
      margin-right: 15px;
    li{
      float: none;
    }
    @include media(mobile){
      overflow-y: auto;
      white-space: initial;
      height: auto;
    }
  }
  }
}
sk-rotating-plane{
  opacity: 1!important;
  //background-color: rgba(0,0,0, 0.5)!important;
  .sk-rotating-plane{
    background: url(../../assets/images/Spinner/logo.svg) transparent no-repeat !important;
    width: 100px!important;
    height: 100px!important;
    display: block;
    animation: none!important;
    -webkit-animation: none!important;
    background-size: 100px;
    filter: brightness(0) invert(1);

  }
}
#spinner{
  opacity: 1!important;
  background-color: rgba(0,0,0, 0.4)!important;
  .sk-wave{
    background: url(../../assets/images/Spinner/logo.svg) transparent no-repeat !important;
    width: 100px!important;
    height: 100px!important;
    display: block;
    animation: none!important;
    -webkit-animation: none!important;
    background-size: 100px;
    filter: brightness(0) invert(1);
    .sk-rect{
      display: none!important;
    }

  }
}

.inLine {
  display: flex;
  flex-direction: row;
}

:root {
  --primary-color: #9C49D4 !important;
}

.primary {
  color: var(--primary-color) !important;
}

.ql-container {
  font-family: $inter, sans-serif !important;
  h1, h2, h3, h4, h5, h6 {
    font-family: $HindGuntur,sans-serif !important;
  }
  .ql-editor {
    padding: 0px !important;
  }
}
//calendar stype start//
mwl-demo-component {
  .cal-month-view {
    .cal-day-number {
      opacity: 1;
    }
    .cal-day-cell.cal-out-month {
      .cal-day-number {
        opacity: 0.7;
      }
    }
    .cal-open-day-events{
      font-size: 14px;
    }
  }
  .cal-week-view {
    margin-right: 17px;
    .cal-day-headers {
      span {
        opacity: 0.7;
      }
    }
    .cal-event  {
      color: #fff;
      font-size: 14px;
    }
    .cal-day-column {
      border-left: none;
    }
  }
}
.cal-time-events {
  height: 1000px;
  overflow-y: scroll;
  margin-right: -4px;
  .cal-hour {
    border-left: 1px solid #e1e1e1;
  }
}
@-moz-document url-prefix() {
  .cal-time-events {
    margin-right: -16px;
  }
}
//calendar stype end//
.shadow-remove{
  box-shadow: none !important;
  border-radius: 8px !important;
}
.toast-container {
  z-index: 9999999!important;
}
