@charset "UTF-8";
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();
@include mat.all-component-typographies();
$virtual-campus-v2-primary: mat.define-palette(mat.$indigo-palette);
$virtual-campus-v2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$virtual-campus-v2-warn: mat.define-palette(mat.$red-palette);

$virtual-campus-v2-theme: mat.define-light-theme((
    color: (
      primary: $virtual-campus-v2-primary,
      accent: $virtual-campus-v2-accent,
      warn: $virtual-campus-v2-warn,
  )
));

@include mat.all-component-themes($virtual-campus-v2-theme);

/* You can add global styles to this file, and also import other style files */
@import 'helpers/variables/palette';
@import 'helpers/mixins/grid';
@import 'core/grid';
@import 'core/typography';
@import 'core/tables';
@import 'forms/buttons';
@import 'forms/form-elements';
@import 'forms/form-validation';
@import 'core/main';
@import 'core/footer';
@import 'icons/user';
@import 'icons/menu';
@import 'icons/close';
@import 'icons/search';
@import 'icons/alert';
@import 'bootstrap_slider';
@import 'icons/chat';
@import 'icons/favourite';
@import 'responsive.scss';
@import 'core/tailwindCommon';
@import 'core/toaster';
@import 'core/matoverride';
@import 'commonCustome';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'core/newsurveyjs';
@import "~leaflet/dist/leaflet.css";
@import 'core/aisCommon';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.no-scroll{
  overflow: hidden !important;
}

.sv-tagbox__item {
  background-color: #562672 !important;
}

#assessment_container .sd-progress--pages .sd-root-modern__wrapper .sd-container-modern .sv-list__item.sv-list__item--selected > .sv-list__item-body, #assessment_container .sd-progress--pages .sd-root-modern__wrapper .sd-container-modern .sv-list__item.sv-list__item--selected:hover > .sv-list__item-body {
  background-color: #562672 !important;
  color: #fff !important;
}