@import '../helpers/variables/base';
#assessment_container{
    padding-top: 24px !important;
    .sd-progress--pages{
        border-radius: 16px !important;
        background-color: #F9FAFB !important;
        
        .sd-root-modern__wrapper{
            .sd-title{
                color: #331643;
                font-family: $HindGuntur-Bold;
                font-size: 48px;
                font-style: normal;
                font-weight:700;
                letter-spacing: -0.96px;
                
            }
            .sd-container-modern{
                .sd-container-modern__title{
                    border-radius: 16px;
                    border: 1px solid#E5E7EB;
                    background: #FFF;
                    box-shadow: none !important;
                    overflow: hidden !important;
                    margin-bottom: 24px !important;
                }
                .sd-description {
                    .sv-string-viewer--multiline{
                    color: #503C5C !important ;
                    }
                }
                // .sd-action-bar{
                //     display: none;
                // }
                .sd-question__header--location-top{
                    padding-top: 16px !important;
                    padding-bottom: 16px !important
                }
                .sd-item{
                    padding-top: 0 !important;
                }
                .sd-element--with-frame{
                    border-radius: 16px !important;
                    border: 1px solid #E5E7EB;
                    box-shadow: none !important;
                }
                .sd-body.sd-body--responsive{
                    padding: 0 !important;

                }
                .sd-body.sd-body--static{
                    padding-bottom: 0 !important;

                }
                .sd-element--nested-with-borders{
                    border: none !important;
                    padding: 0 !important;
                }
                .sd-element__num{
                    display: none !important;
                }
                .sd-element__title .sv-string-viewer.sv-string-viewer--multiline{
                    font-size: 24px !important;
                }
                .sd-item__control-label .sv-string-viewer{
                    font-size: 16px !important;
                    color: #503C5C !important;

                }
                .sd-radio__decorator{
                    border: 1px solid var(--Grey-Grey-300, #D2D6DB);
                    background: var(--Base-White, #FFF);
                }
                // .sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after{
                //     background-color: #331643 !important;
                //     border: 1px solid #331643 !important;
                // }
                .sd-html--nested{
                    color: #503C5C !important;
                    strong{
                        color: #331643 !important;
                    }
                }
                .sd-panel.sd-element--nested>.sd-panel__content{
                    border-top: 1px solid #D2D6DB;
                }
                .sd-item--checked .sd-item__decorator, .sd-matrix .sd-item__control:focus+.sd-item__decorator, .sd-matrix .sd-item--checked .sd-item__decorator {
                    background: #331643 !important;
                    box-shadow: 0 0 0 1px #331643 !important;
                }
                // .sd-item--checked .sd-item__decorator, .sd-item__decorator, .sd-item--checked .sd-item__decorator {
                //     background: #331643 !important;
                //     box-shadow : 0 0 0 1px #331643;
                // }
                .sd-item__decorator{
                    background: #fff !important;
                    border: 1px solid #D2D6DB !important;
                }
                span .sd-radio--checked .sd-radio__decorator:before {
                    background: #331643 !important;
                }
                .sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator::before {
                    background-color: #fff !important;
                    box-shadow : 0 0 0 1px #331643 !important;
            
                }
                .sd-item__control:focus+.sd-item__decorator{
                    box-shadow : 0 0 0 1px #fff !important;
                }
                .sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after {
                    background-color: #fff!important;
                    box-shadow : 0 0 0 1px #331643 !important;
            
                }
                .sd-checkbox--checked .sd-checkbox__control:focus+.sd-checkbox__decorator .sd-checkbox__svg use {
                    fill: #fff;
                }
                .sd-checkbox--checked.sd-checkbox--readonly .sd-checkbox__svg use {
                    fill: var(--sjs-general-forecolor, var(--foreground, #fff)) !important;
                }
                .sd-radio--checked.sd-radio--readonly .sd-radio__decorator:after {
                    background-color: var(--sjs-general-forecolor, var(--foreground, #fff)) !important;
                }
                .sd-rating__item--selected {
                    background-color: var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                    color: #fff !important;
                    box-shadow: 0 0 0 0px var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                    .sv-string-viewer{
                        color: #fff !important;
                    }
                }
                .sd-rating__item:focus-within {
                    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sd-input:focus-within{
                    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sd-input.sd-dropdown:focus-within{
                    box-shadow: 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sd-action:not(.sd-action--pressed):hover{
                    background-color: var(--sjs-primary-backcolor-light, var(--primary-light, #D2D6DB)) !important;
                }
                .sv-list__item.sv-list__item--selected>.sv-list__item-body, .sv-list__item.sv-list__item--selected:hover>.sv-list__item-body{
                    background-color: var(--sjs-primary-backcolor, var(--primary-light, #331643)) !important;
                    color: #fff !important;
                }
                input[title="Complete"] {
                    display: none !important;
                }
                .sd-imagepicker__check-icon{
                    fill: var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sd-file__choose-btn--text .sv-svg-icon {
                    fill: var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sd-btn{
                    color: #331643 !important;
                    height: 53px !important;
                }
                .sv-action__content{
                    .sd-action{
                        span{
                            color: #331643 !important;
                        }
                    }
                }
                .sd-row{
                    margin-top: 0 !important;
                }
                .sd-page{
                    gap: 24px !important;
                    margin-bottom: 24px !important;
                }
                sv-ng-text-question{
                    .sd-input{
                        margin-bottom: 1em !important;
                        color:#503C5C !important
                    }
                }
                .sd-dropdown{
                    margin-bottom: 1em !important; 
                }
                .sd-comment{
                    margin-bottom: 24px;
                    color:#503C5C !important
                }
                // .sd-progress__text{
                //     margin-top: 0 !important;
                // }
                
                .sd-btn:focus-visible {
                    box-shadow: var(--sjs-shadow-small-reset, 0px 0px 0px 0px rgba(0, 0, 0, 0.15)), 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
                }
                .sv-string-viewer {
                    font-family: $HindGuntur-Bold, sans-serif !important;
                }
            }
        }
    }
    .sd-paneldynamic{
        .sv-string-viewer{
            color: #503C5C !important ;
        }
    }
    .sv-list__empty-container{
        .sd-list__item .sv-list__item .sv-list__item-text--wrap .sd-list__item--selected .sv-list__item--selected{
            .sv-string-viewer{
                color: #fff !important;
            }
        }
    }
    .sv-dropdown-popup .sv-list__item--selected span{
        color: #fff !important ;
    }
    .sd-root-modern--mobile .sd-body.sd-body--static {
        padding: 3em 0 !important;
        .sd-body__navigation{
        padding-bottom: 1em !important;
        }
    }
    .sd-body__progress--bottom .sd-progress__text {
        margin-top:0 !important;
    }
    .sd-progress__bar {
        background-color: var(--sjs-primary-backcolor, var(--primary, #331643)) !important;
    }
    .sd-root-modern.sd-root-modern--full-container{
        overflow: visible !important;
    }
}
