@import "../helpers/mixins/notifications";

.form-group-error {
  @include contain-floats();
  margin-right: 15px;
  border-left: 4px solid $error-colour;
  padding-left: 10px;

  @include media(tablet) {
    border-left: 5px solid $error-colour;
    padding-left: $gutter-half;
  }

  textarea,
  input {
    border: 2px solid #d4351c;
    outline: 2px solid #d4351c;
    outline-offset: 0;
    box-shadow: none;
  }
  select {
    border: 2px solid #d4351c;
    outline: 2px solid #d4351c;
    outline-offset: 0;
    box-shadow: none;
  }
}

.form-control-error {
  border: 4px solid $error-colour;
}

.error-message {
  @include notification-message($error-colour);
  margin: 5px 0 15px 0;
}

.success-message {
  @include notification-message($green);
}

.form-label .error-message,
.form-label-bold .error-message {
  padding-top: 4px;
  padding-bottom: 0;
}

.error-summary {
  @include notification-summary($error-colour);
  
  .error-summary-heading {
    @include notification-summary-heading();
  }
  
  .error-summary__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.25;
    font-family: 'HindGuntur-Bold', sans-serif;
  }

  .error-summary-body {
    padding: 20px;
  }

  .error-summary-list {
    @include notification-summary-list($error-colour);
    margin: auto auto 0 0;
    font-family: 'HindGuntur-Bold', sans-serif;
  }
}

.success-summary {
  @include notification-summary($green);

  .success-summary-heading {
    @include notification-summary-heading();
  }

  .success-summary-list {
    @include notification-summary-list($black);
  }
}