.mat-error {
  margin-top: 2px;
  margin-bottom: 2px;
}
.mat-input-element {
  height: 32px !important; /* Adjust height as needed */
  // padding: 8px; /* Adjust padding as necessary */
}
.mat-form-field {
  height: 40px !important; /* Adjust height of mat-form-field */
}

.mat-form-field-wrapper {
  height: 40px !important; /* Adjust height of mat-form-field wrapper */
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 !important;
}

.mat-form-field-subscript-wrapper {
  // font-size: 75%;
  margin-top: 2.2em;
  // top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-outline .mat-form-field-suffix .mat-error-icon {
  color: red !important;
}

.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border: 1px solid #D2D6DB !important;
}

.mat-form-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border-color: #DCBDF5;
}

.mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start {
  border-right-style: none !important;
}

.mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border-left-style: none !important;
}



.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start {
  border-right-style: none !important;
}

.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border-left-style: none;
}

.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  border-color: #DCBDF5;
  box-shadow: 0 0 5px #DCBDF5;
  border-radius: 5px;
}

.mat-focused.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  box-shadow: 0 0 5px #FDA29B;
  border-radius: 5px;
}

.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border: 1px solid #FDA29B;
}

.mat-form-field.mat-focused.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-start,
.mat-form-field.mat-focused.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline .mat-form-field-outline-end {
  border-color: #FDA29B;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -0.25rem !important;
}

.mat-drawer-container{
  background-color: #fff !important;
}

//checkbox
.mat-checkbox-checkmark-path {
  stroke: #8636BA !important;
}
.mat-checkbox-checked .mat-checkbox-frame {
border: 1px solid #8636BA !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: unset;
}

.active .menu-active {
color: var(--primary-color) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #562672;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
border-color: #562672;
}

.mat-progress-bar-fill::after {
  background-color: #7953C2 !important;
}


.mat-progress-bar {
  border-radius: 12px;
}

.elcas .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix,
.elcas .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  top: 0.125rem !important;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
  color: #FDA29B !important;
}
.mat-form-field .mat-form-field-prefix .mat-datepicker-toggle .mat-mdc-button-base, .mat-form-field .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-button-base {
  padding: 10px 0 !important;
}
.mat-form-field-infix::after {
  margin-top: 3px !important;
}

// for datepicker
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #562672 !important;
  color: #fff !important;
  padding-top: 6px !important;
}
.mat-calendar-body-selected {
  background-color: #562672 !important;
  color: #fff !important;
  padding-top: 6px !important;

}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #F4EBFC !important;
  color: #562672 !important;
  padding-top: 6px !important;

}
.mat-datepicker-toggle-active {
  color: #DCBDF5 !important;
}
.mat-datepicker-toggle {
  color: #562672 !important;
}
.mat-mdc-icon-button:disabled {
  color: #9DA4AE !important;
}
.mat-menu-content{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mat-calendar-body-cell-content .mat-focus-indicator .mat-calendar-body-today{
  padding-top: 6px !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  padding-top: 6px !important;
}
.mat-calendar-body-cell-content.mat-focus-indicator {
  padding-top: 6px !important;
}
.mat-form-field-infix{
  padding: 0% !important;
  margin: 0% !important;
}
.mat-drawer-content{
display: inline !important;
}
.mat-drawer-inner-container{
  overflow: hidden !important;
}
.mat-calendar-body-cell{
  font-family: 'Hindguntur', sans-serif !important;
}
.align_compare_course .mat-mdc-menu-item-text {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 215px !important;
}
.dos_landing_input_overwrite .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}
