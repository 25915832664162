@use 'sass:math';
@font-face {
    font-family: 'open_sansbold';
    src: url('/assets/fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/opensans-bold-webfont.woff') format('woff'),
         url('/assets/fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('/assets/fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('/assets/fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/opensans-regular-webfont.woff') format('woff'),
         url('/assets/fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'HindGuntur-Regular', sans-serif;
    src: url('/assets/fonts/HindGuntur-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'HindGuntur-Bold', sans-serif;
    src: url('/assets/fonts/HindGuntur-Regular-Bold-Font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'HindGuntur';
    src: url('/assets/fonts/HindGuntur-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'HindGuntur-Bold', sans-serif;
    src: url('/assets/fonts/HindGuntur-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

$site-width: 1220px;

$mobile-breakpoint: 601px !default;
$tablet-breakpoint: 768px !default;
$iphone-breakpoint: 812px !default;
$desktop-breakpoint: 992px !default;

$full-width: 100%;
$one-quarter: math.div($full-width , 4);
$one-third: math.div($full-width , 3);
$half: math.div($full-width , 2);
$two-thirds: ($full-width) - ($one-third);
$three-quarters: ($full-width) - ($one-quarter);

$gutter: 30px;
$gutter-one-quarter: math.div($gutter , 4);
$gutter-one-third: math.div($gutter , 3);
$gutter-half: math.div($gutter ,2);
$gutter-two-thirds: $gutter - $gutter-one-third;

$HindGuntur-Regular-regular: 'HindGuntur-Regular';
$is-print: false !default;
$base-font-size: 16;


$inter : 'Inter';
$HindGuntur-Regular : 'HindGuntur-Regular';
$HindGuntur-Bold : 'HindGuntur-Bold';
$HindGuntur : 'HindGuntur';
