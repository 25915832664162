@import '../styles/helpers/variables/base';
li.active {
  border-bottom: none !important;

  a {
    color: var(--primary-color) !important;
    font-weight: bold;
  }
}

.bg-img-cource {
  background-image: url("../assets/images/BackgroundImages/Courses.svg");
  background-repeat: no-repeat;
}

.bg_img_jobs_block {
  background-image: url("../assets/images/JobsImageSVG/Container.png");
  background-repeat: no-repeat;
}

.font_hindguntur_bold {
  font-family: $HindGuntur-Bold,  sans-serif;
}

.font_hindguntur {
  font-family: $HindGuntur, sans-serif;
}

.font_inter {
  font-family: $inter, sans-serif;
}

li {
  list-style: none;
}

quill-view {
  font-family: $HindGuntur, sans-serif;
}

.ql-clipboard {
  display: none;
}

/* Styling for selected radio button */
.selected {
  background-color: #FBF6FE;
  /* Light purple */
  border: 2px solid #562672;
  /* Dark purple border */
}

.selected mat-radio-button .font_inter {
  color: #562672;
  /* Purple */
}

.payment-img img {
  width: 34px;
  height: 24px;
}

.ql-editor {
  p {
    word-break: break-word !important;
  }
}

//chat 
.chat_text_wrap {
  overflow-wrap: anywhere
}

.bg-one {
  background-color: #9C49D4;
}

.bg-two {
  background-color: #7953C2;
}

.bg-three {
  background-color: #249BB6;
}

.bg-four {
  background-color: #FFEB1E;
}

a.active {
  border-bottom: none !important;

  a {
    border-bottom: none !important;

    h5 {
      color: var(--primary-color) !important;
      font-weight: bold;
    }
  }
}


a.header_profile_box_link.active {
  color: var(--primary-color) !important;
  font-weight: bold;
}
.bg-img-article {
  background-image: url("../assets/images/BackgroundImages/article.svg");
  background-repeat: no-repeat;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 3.2em; 
}
.dos-chip-active {
  color: #F4EBFC !important;
  background-color: #331643 !important;
  cursor: pointer !important;
}

.popup-container{
  font-family: $HindGuntur, sans-serif;
}

.bg-img-home {
  background-image: url("../assets/images/BackgroundImages/home-bg.svg");
  background-repeat: no-repeat;
}
.box-with-top-shadow {
  background: white;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.3);
}
.dos_services_details_paragraph a {
  color: #0066cc !important;
  text-decoration: underline !important;
}
select{
  background-color: white !important;
}

// Sector pathway carousel 
.carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}
.carousel-container {
    overflow: hidden;
  }
  
  .carousel-track {
    display: flex;
    transition: transform 0.5s ease-in-out;
    gap: 1.5%;
  }
  
  .carousel-slide {
    flex: 0 0 60%; /* First slide takes 60% of the width */
    display: flex;
    align-items: baseline;
    justify-content: center;
      .relative {
        width: 100%;
      }
  }  
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
  }
  
  .carousel-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .button-container {
    position: absolute;
    right: 0;
    bottom: -71px;
    .prev-button {
      margin-right: 10px;
      position: relative;
    }
    
    .next-button {
      position: relative;
    }
  }
  
  
  .bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .bullet.active {
    background-color: #333;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .carousel-slide {
      flex: 0 0 95%;
      .relative, .items-center {
        width: 100%;
        padding: 17px;
        .items-start {
          width: 100%;
        }
        img {
          min-height: 300px;
        }
      }
    }
    .bullet {
      width: 8px;
      height: 8px;
    }
  }
.carousel-pagination {
  text-align: center;
    margin-top: 10px;
    position: absolute;
    bottom: -39px;
  .bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .bullet.active {
    background-color: #333;
  }
}
input:placeholder-shown {
  text-overflow: ellipsis;
}

.with-notch::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 22px;
  background-color: #B1A3E2;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.with-notch-sm::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 22px;
  background-color: #B1A3E2;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.anchor-color-send a {
  color: white;
  text-decoration: underline;
}

.anchor-color-send a:visited {
  color: #C8A2C8;
  text-decoration: underline;
}

.anchor-color-receive a {
  color: black;
  text-decoration: underline;
}

.anchor-color-receive a:visited {
  color: grey;
  text-decoration: underline;
}

@media screen and (min-width: 1025px) and (max-width: 1500px) {
  .chat-height {
    min-height: 57vh !important;
    max-height: 59vh !important;
  }
}
