.alert {
    height: 1.4em;
    width: 1.4em;
    background-color: $orange;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 4px;

    &:after {
    display: block;
    position: absolute;
    top: -4px;
    left: -1px;
    width: 100%;
    padding: 0 1px;
    text-align: center;
    content: "!";
    font-size: 1.3em;
    font-family: 'HindGuntur-Bold', sans-serif;
    color: $white;
  }
}