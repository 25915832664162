.chat-icon{
    .btn{
        .chat-text{
            float: left;
            font-family: 'HindGuntur-Bold', sans-serif;
            color: #1d70b8;
            line-height: 1.4;
          }
          .chat-div{
           position: absolute;
           margin-left: 0;
           right: 4px;
           top: -15px;
           width: 34px;
           height: 44px;
           .chat-btn {
            width: 15px;
            height: 11px;
            background-color: #fff;
            border-radius: 25px;
            position: relative;
            border: 2px solid #212121;
            box-shadow: 0 0 0 2px #fff;
            &:after{
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
              left: -1px;
              bottom: -6px;
              border-right: 2px solid transparent;
              border-left: 2px solid transparent;
              border-top: 6px solid #212121;
              transform: rotate(40deg);
            }
          }
          .chat-btn1 {
            width: 15px;
            height: 11px;
            background-color: #212121;
            border-radius: 25px;
            border: 2px solid #212121;
            position: relative;
            top: 19px;
            left: 4px;
            transform: rotate(-20deg);
            &:after{
              content: "";
              display: block;
              position: absolute;
              width: 0;
              height: 0;
              left: 6px;
              bottom: -8px;
              border-right: 3px solid transparent;
              border-left: 2px solid transparent;
              border-top: 9px solid #212121;
              transform: rotate(-30deg);
            }
          }
          }
        &:focus{
            .chat-div{
                .chat-btn{
                  //  background-color: $yellow;
                   // box-shadow: 0 0 0 2px $yellow;
                }
            }
        }
    }
 .relative{
   position: relative;
 }
}
//this 1 change under VC-2635
.chat-text {
  height: 30px;
}