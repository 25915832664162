@import '../helpers/variables/base';
.ngx-toastr {
    height: auto !important;
    padding: 10px 10px 10px 50px !important;
    // max-height: 70px;
    width: max-content !important;
    overflow-y: hidden;
    /* Hide overflow to ensure single line */
    max-width: 550px !important;
    font-family: $HindGuntur, sans-serif;
  }